import React, { useState, useEffect } from "react";
import GMCDetailImage from "../../assets/img/gmc-details-image.jpg";
import GMCDetailGallery from "../../assets/img/rate-details-gallery.png";
import GMCRightFace from "../../assets/img/gmc-right-face.png";
import PerHour from "../../assets/img/per-hour-rate.svg";
import User from "../../assets/img/User.png";
import PerDay from "../../assets/img/per-day-rate.svg";
import AirportTransfer from "../../assets/img/airport-transfer.svg";
import Avatar from "../../assets/img/avatar placeholder.png";
import RoundArrow from "../../assets/img/Arrow - Right Circle.svg";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ToastProvider } from "react-toast-notifications";

import "./blog-detail.css";

const ProductDetailContent = ({ blog, comments, replies, coverImage }) => {
  const { id } = useParams();
  const { addToast } = useToasts();

  const [comment, setComment] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [replyComment, setReplyComment] = useState();
  const [replyName, setReplyName] = useState();
  const [replyEmail, setReplyEmail] = useState();
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [numberOfComments, setNumberOfComments] = useState();
  const [formattedTime, setFormattedTime] = useState(null);
  const [tags, setTags] = useState(null);
  const [allTags, setAllTags] = useState();
  const [isMostRecent, setIsMostRecent] = useState(true);
  const [isShowReplyForm, setIsShowReplyForm] = useState(false);
  const [isShowReplyFlag, setIsShowReplyFlag] = useState(false);
  const [commentId, setCommentId] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSortOrder = () => {
    setIsMostRecent((prev) => !prev);
  };

  const itemsPerPage = 10;

  const totalPages = Math.ceil(blog?.comments?.length / itemsPerPage);
  console.log("Total Pages: ", totalPages);

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const sortedComments = blog?.comments?.sort((a, b) => {
    if (isMostRecent) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
  });

  const currentValues = sortedComments?.slice(firstIndex, lastIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const filteredImages =
      blog && blog?.imgurls?.filter((el) => el?.name?.includes("detailImages"));
    const commentsWithAction1 =
      blog?.comments?.filter((el) => el.action === "1") || [];
    setNumberOfComments(commentsWithAction1.length);
    setFilteredImages(filteredImages);
    setSelectedImage(filteredImages?.[0].name);
  }, [blog]);

  useEffect(() => {
    setTags(blog && blog?.tags && JSON.parse(blog?.tags));
    const dateObject = new Date(blog?.createdAt);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const outputTime = dateObject.toLocaleDateString("en-US", options);
    setFormattedTime(outputTime);
  }, [blog]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/tags/get`)
      .then((res) => {
        setAllTags(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const submit = async () => {
    let temp = {};
    temp.text = comment;
    temp.name = name;
    temp.email = email;
    temp.blog_id = id;
    temp.commentId = 0;
    temp.action = 0;

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/admin/comments`, temp)
      .then((res) => {
        addToast("Comment created successfully!", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setComment("");
        setName("");
        setEmail("");
      })
      .catch((err) => {
        addToast(err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      });
  };

  const addReply = (commentId) => {
    let temp = {};
    temp.text = replyComment;
    temp.name = replyName;
    temp.email = replyEmail;
    temp.blog_id = id;
    temp.commentId = commentId;
    temp.action = 0;

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/admin/comments`, temp)
      .then((res) => {
        addToast("Comment created successfully!", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setComment("");
        setName("");
        setEmail("");
        setIsShowReplyForm(false);
      })
      .catch((err) => {
        addToast(err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      });
  };

  const replyClickHandler = (id) => {
    setIsShowReplyForm(!isShowReplyForm);
    setCommentId(id);
  };

  const checkReplyClickHandler = (id) => {
    setIsShowReplyFlag(!isShowReplyFlag);
    setCommentId(id);
  };

  return (
    <section id="rate-details-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="details-content">
              <div className="car-details-image">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL + coverImage}`}
                  alt={blog?.alt_text}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="sidebar-details blog-details">
              <div className="row">
                <div className="col-lg-2 big-ic blog-author blog-detail-user">
                  <img src={User} alt="" className="avatar-blog-details" />
                </div>
                <div className="col-lg-10">
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <h5
                      style={{
                        fontSize: "17px",
                        marginBottom: "0px",
                        marginLeft: "18px",
                      }}
                    >
                      Author:{" "}
                    </h5>{" "}
                    <span> &nbsp;Limousine4hire</span>
                  </div>
                  <div className="row" style={{ marginLeft: "12px" }}>
                    <ul id="social-icon" className="blog-detail">
                      <li>
                        <Link
                          // to={data?.social_media_facebook}
                          className="hvr-bounce-in social-icon-blog-detail"
                        >
                          <i
                            className="fa-brands fa-facebook"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to={data?.social_media_twitter}
                          className="hvr-bounce-in social-icon-blog-detail"
                        >
                          <i
                            className="fa-brands fa-twitter"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to={data?.social_media_linkedin}
                          className="hvr-bounce-in social-icon-blog-detail"
                        >
                          <i
                            className="fa-brands fa-linkedin-in"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to={data?.social_media_youtube}
                          className="hvr-bounce-in social-icon-blog-detail"
                        >
                          <i
                            className="fa-brands fa-youtube"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to={data?.social_media_instagram}
                          className="hvr-bounce-in social-icon-blog-detail"
                        >
                          <i
                            className="fa-brands fa-instagram"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <ul className="unstyled ps-4 ">
                  <li className="d-flex justify-content-start align-items-center mb-3">
                    <i
                      className="fa-solid fa-clock"
                      style={{ color: "#8c8c8c", fontSize: "22px" }}
                    ></i>
                    <p className="ps-3">{formattedTime}</p>
                  </li>
                  <li className="d-flex justify-content-start align-items-center mb-3">
                    <i
                      className="fa-regular fa-folder-open"
                      style={{ color: "#8c8c8c", fontSize: "22px" }}
                    ></i>
                    <p className="ps-3">
                      By Limousine 4 hire News, Special Events, Wedding
                      Transportation, Los Angeles News
                    </p>
                  </li>
                  <li className="d-flex justify-content-start align-items-center mb-3">
                    <i
                      className="fa-solid fa-comment-dots"
                      style={{ color: "#8c8c8c", fontSize: "22px" }}
                    ></i>
                    <p className="ps-3">By Limousine 4 hire</p>
                  </li>
                  <li className="d-flex justify-content-start align-items-center mb-3">
                    <i
                      className="fa-solid fa-comment-dots"
                      style={{ color: "#8c8c8c", fontSize: "22px" }}
                    ></i>
                    <p className="ps-3">{numberOfComments} Comments</p>
                  </li>
                </ul>
              </div>

              <div className="form-type-area">
                <div className="row ms-3">
                  <h4 className="p-0">Hashtags</h4>

                  {tags &&
                    tags?.map((el, i) => (
                      <div key={i} className="form-overlay blog-detail m-1">
                        {el.value}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="blog-title" />
          <h2>{blog?.title}</h2>
          <p style={{ fontSize: "12px", marginBottom: "8px" }}>
            By Limosine 4 hire
          </p>
        </div>
        <div className="row">
          <div
            className="detail-text text-muted"
            dangerouslySetInnerHTML={{ __html: blog?.content }}
          />
        </div>
        <div className="row" style={{ marginTop: "40px" }}>
          <h3 className="mb-0" style={{ fontSize: "25px", fontWeight: "700" }}>
            Post a Comment
          </h3>
          <form
            action="get"
            id="contact-form"
            className="forms blog-comment-form mt-3"
          >
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="input_type mb-4">
                  <div className="input-group">
                    <textarea
                      name="Message"
                      id="Message"
                      placeholder="Your comment"
                      className="form-control"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="input_type mb-4">
                      <div className="input-group">
                        <input
                          id="name"
                          className="form-control"
                          type="text"
                          placeholder="Your name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="input_type mb-4">
                      <div className="input-group">
                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex align-items-center mb-4">
                  <input
                    id="checkbox"
                    className=""
                    type="checkbox"
                    placeholder=""
                  />
                  <label className="ms-2 mb-1" style={{ fontSize: "16px" }}>
                    Save my name , Email, and website in this browser for the
                    next time comment{" "}
                  </label>
                </div> */}
                <div className="submit_button">
                  <Link
                    className="btn  blog-detail-button"
                    role="button"
                    onClick={submit}
                  >
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row" style={{ marginTop: "50px" }}>
          <div className="top-row">
            <h3 style={{ fontSize: "28px", fontWeight: "600" }}>
              {numberOfComments} Comments
            </h3>

            <UncontrolledDropdown
              nav
              inNavbar
              onMouseOver={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
            >
              <DropdownToggle
                nav
                caret
                className="nav-link-most-recent dropdown-toggle-most-recent"
                onClick={toggleSortOrder}
              >
                {isMostRecent ? "MOST RECENT" : "LEAST RECENT"}
              </DropdownToggle>
            </UncontrolledDropdown>
          </div>
          <div className="seperator"></div>

          {currentValues &&
            currentValues
              ?.map((el, i) => {
                if (el.action === "1") {
                  const targetDateTime = new Date(el?.createdAt).getTime();
                  const currentDate = new Date().getTime();
                  const differenceInMilliseconds = currentDate - targetDateTime;
                  const differenceInDays = Math.floor(
                    differenceInMilliseconds / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <div className="comments" style={{ marginBottom: "20px" }}>
                      {el.commentId == 0 && (
                        <>
                          <div
                            className="d-flex gap-2"
                            style={{ alignItems: "baseline" }}
                          >
                            <div className="">
                              <img
                                src={Avatar}
                                alt="avatar"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </div>
                            <div className="">
                              <h3
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "800",
                                  color: "#63637B",
                                }}
                              >
                                {el?.comment_user?.first_name
                                  ? el?.comment_user?.first_name
                                  : el?.name}
                              </h3>
                            </div>
                            <div className="">
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#9191A8",
                                }}
                              >
                                •
                              </p>
                            </div>
                            <div className="">
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#9191A8",
                                }}
                              >
                                {differenceInDays} days ago
                              </p>
                            </div>
                          </div>
                          <div className="row ms-3">
                            <p style={{ fontSize: "16px", color: "#292936" }}>
                              {el.text}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              className="comments-icon-wrapper"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="comments-icon d-flex align-items-center"
                                style={{
                                  padding: "5px 10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => replyClickHandler(el.id)}
                              >
                                <span>
                                  <svg
                                    width="10"
                                    height="14"
                                    viewBox="0 0 10 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.99607 8.81125C9.98293 8.90117 9.94317 8.98577 9.88143 9.05493L5.71453 13.8297C5.61954 13.9376 5.47945 14 5.3318 14C5.18403 14 5.04393 13.9376 4.94895 13.8297L0.782053 9.05493C0.701831 8.96179 0.661486 8.843 0.66916 8.72255C0.676833 8.6022 0.731943 8.48896 0.823442 8.40579C0.914941 8.32253 1.03597 8.27546 1.16236 8.27401L2.82388 8.27401C2.81318 7.19745 2.59868 3.49471 0.120635 0.78844C0.0254154 0.682636 -0.0164414 0.542307 0.00588129 0.404413C0.0283203 0.266635 0.112611 0.144959 0.236897 0.0715722C0.361069 -0.00192216 0.512675 -0.0197963 0.651832 0.0225002C4.96606 1.35963 6.87569 5.37847 7.70431 8.27374L9.49605 8.27374C9.64022 8.27274 9.77787 8.33114 9.87369 8.43405C9.9695 8.53697 10.014 8.6743 9.99611 8.81098L9.99607 8.81125ZM8.43348 9.22901L7.32919 9.22901C7.21839 9.22901 7.11062 9.19392 7.02296 9.12909C6.93518 9.06426 6.87263 8.97344 6.84484 8.87086C6.22457 6.53134 4.83592 3.36027 2.01124 1.68882C3.97867 4.88649 3.8439 8.47296 3.82902 8.7765C3.82227 8.89873 3.7667 9.01397 3.67369 9.09811C3.58056 9.18238 3.4572 9.22923 3.32896 9.22912L2.22991 9.22912L5.3289 12.7852L8.43348 9.22901Z"
                                      fill="#000000"
                                    />
                                  </svg>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      color: "#000",
                                      marginLeft: "5px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Reply
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div
                              className="reply-wrapper d-flex gap-3"
                              style={{
                                width: "fit-content",
                                cursor: "pointer",
                              }}
                              onClick={() => checkReplyClickHandler(el.id)}
                            >
                              <p style={{ marginBottom: "0" }}>
                                {
                                  replies?.filter(
                                    (item) =>
                                      item.commentId == el.id &&
                                      item.action == "1"
                                  ).length
                                }{" "}
                                replies
                              </p>
                              <div>
                                <img
                                  src={Avatar}
                                  alt="image"
                                  style={{ marginRight: "-10px" }}
                                />
                                <img src={Avatar} alt="image" />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {isShowReplyForm && commentId == el.id && (
                        <form
                          action="get"
                          id="contact-form"
                          className="forms blog-comment-form mt-3"
                          style={{ margin: "0 auto", width: "80%" }}
                        >
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="input_type mb-4">
                                <div className="input-group">
                                  <textarea
                                    name="Message"
                                    id="Message"
                                    placeholder="Your comment"
                                    className="form-control"
                                    value={comment}
                                    onChange={(e) =>
                                      setReplyComment(e.target.value)
                                    }
                                  ></textarea>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="input_type mb-4">
                                    <div className="input-group">
                                      <input
                                        id="name"
                                        className="form-control"
                                        type="text"
                                        placeholder="Your name"
                                        value={name}
                                        onChange={(e) =>
                                          setReplyName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="input_type mb-4">
                                    <div className="input-group">
                                      <input
                                        id="email"
                                        className="form-control"
                                        type="text"
                                        placeholder="Your email"
                                        value={email}
                                        onChange={(e) =>
                                          setReplyEmail(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="submit_button">
                                <Link
                                  className="btn  blog-detail-button"
                                  role="button"
                                  onClick={() => addReply(el.id)}
                                >
                                  Submit
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                      {replies?.map((item) => {
                        const targetDateTime = new Date(
                          item?.createdAt
                        ).getTime();
                        const currentDate = new Date().getTime();
                        const differenceInMilliseconds =
                          currentDate - targetDateTime;
                        const differenceInDays = Math.floor(
                          differenceInMilliseconds / (1000 * 60 * 60 * 24)
                        );
                        return (
                          <>
                            {isShowReplyFlag &&
                              item.action == "1" &&
                              item.commentId == el.id &&
                              commentId == el.id && (
                                <>
                                  <div
                                    className="d-flex gap-2"
                                    style={{
                                      alignItems: "baseline",
                                      marginLeft: "50px",
                                      marginRight: "50px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div className="">
                                      <img
                                        src={Avatar}
                                        alt="avatar"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </div>
                                    <div className="">
                                      <h3
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "800",
                                          color: "#63637B",
                                        }}
                                      >
                                        {item.name}
                                      </h3>
                                    </div>
                                    <div className="">
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                          color: "#9191A8",
                                        }}
                                      >
                                        •
                                      </p>
                                    </div>
                                    <div className="">
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                          color: "#9191A8",
                                        }}
                                      >
                                        {differenceInDays} days ago
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row ms-3">
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#292936",
                                        marginLeft: "55px",
                                      }}
                                    >
                                      {item.text}
                                    </p>
                                  </div>
                                </>
                              )}
                          </>
                        );
                      })}
                    </div>
                  );
                }
              })}
        </div>
        <div className="row">
          <Pagination>
            <Pagination.Prev className="customPrev" />
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {" "}
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next className="customNext" />
          </Pagination>
        </div>
      </div>
      <div
        style={{
          marginTop: "50px",
          backgroundColor: "#F4F4F4",
          padding: "50px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="tags d-flex flex-wrap">
              {allTags &&
                allTags?.map((el, i) => {
                  return (
                    <>
                      <div
                        style={{
                          color: "#6C757D",
                          border: "1px solid",
                          borderRadius: "3px",
                          padding: "5px 15px",
                          marginRight: "10px",
                          marginTop: "10px",
                        }}
                      >
                        {el.tag}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetailContent;
