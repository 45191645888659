import React from 'react'
import RateTitleContent from '../../assets/img/rate-title-content.png';

const RatesHeader = ({mainTitle, backgroundImage, foregroundImage}) => {
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + backgroundImage?.[0]?.name})`
  };
  return (
    <section id="page-titles">
    <div className="rates-overlay" style={dynamicBackgroundStyle}>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <h2>{mainTitle}</h2>
        </div>
        <div className="col-lg-9">
            <div className="title-content">
                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL + foregroundImage?.[0]?.name}`} alt="" />
            </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default RatesHeader