import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const [data, setData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pageUrls, setPageUrls] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdown = () => {
    navigate(pageUrls?.page2url);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/services/getActiveServices`)
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/get`)
      .then((res) => res.json())
      .then((resData) => {
        const urls = {};
        const filteredData = resData.filter(
          (item) => item.page_url && item.page_url.trim() !== ""
        );
        filteredData.forEach((item) => {
          const { id, page_url } = item;
          urls[`page${id}url`] = page_url;
        });
        setPageUrls(urls);
      });
  }, []);

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/redirects/get`)
  //     .then((res) => res.json())
  //     .then((resData) => {
  //       const url = resData?.find(
  //         (el) => window.location.pathname === "/" + el.oldUrl
  //       );
  //       if (url && url.newUrl) {
  //         try {
  //           navigate(`/${url.newUrl}`);
  //         } catch (error) {
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  return (
    <header id="main-header">
      <nav className="navbar navbar-expand-lg main-nav-bar navbar-dark">
        <div id="no-flex" className="container">
          <div className="row">
            <div className="col-lg-3 col-xs-4">
              <Link className="navbar-brand" to={"/"}>
                <img src={Logo} alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main-navbar"
                aria-controls="main-navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div className="col-lg-6 col-xs-4">
              <Navbar id="main-navbar">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav
                    className="me-auto justify-content-between m-auto"
                    style={{ width: "85%" }}
                    navbar
                  >
                    <React.Fragment>
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={pageUrls?.page1url}
                        style={{ fontSize: "14px" }}
                        activeclassname="active"
                      >
                        Book a Ride
                      </NavLink>

                      <UncontrolledDropdown
                        nav
                        inNavbar
                        onMouseOver={() => setDropdownOpen(true)}
                        onMouseLeave={() => setDropdownOpen(false)}
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                      >
                        <DropdownToggle
                          nav
                          caret
                          onClick={handleDropdown}
                          style={{ fontSize: "14px" }}
                          className={pathname == "/services" ? "active" : ""}
                        >
                          Services
                        </DropdownToggle>
                        <DropdownMenu end>
                          {data?.map((el, i) => {
                            const jsonData = JSON.parse(el?.urls);
                            const icon = jsonData?.filter((el) =>
                            el?.name.includes("icon")
                            );
                            return (
                              <React.Fragment key={i}>
                                <DropdownItem>
                                  <Link to={el.page_url}>
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_BASE_URL + icon[0]?.name}`}
                                      alt="icon"
                                      style={{
                                        width: "7%",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {el.title}
                                  </Link>
                                </DropdownItem>
                              </React.Fragment>
                            );
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={pageUrls?.page3url}
                        activeclassname="active"
                        style={{ fontSize: "14px" }}
                      >
                        Rates
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={pageUrls?.page4url}
                        activeclassname="active"
                        style={{ fontSize: "14px" }}
                      >
                        Blog
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={pageUrls?.page5url}
                        activeclassname="active"
                        style={{ fontSize: "14px" }}
                      >
                        Contact Us
                      </NavLink>
                    </React.Fragment>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
            <div className="col-lg-3 col-xs-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="telephone">
                    <i className="fa-solid fa-phone"></i>{" "}
                    <span>
                      <a href={`tel:800-499-8128`} style={{ fontSize: "14px" }}>
                        800-499-8128
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="login-menu">
                    <ul className="login-reg">
                      <li>
                        <Link to={"/"} style={{ fontSize: "14px" }}>
                          <i className="fa-solid fa-user"></i>Login
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"} style={{ fontSize: "14px" }}>
                          Register
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
