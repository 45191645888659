import React, { useEffect, useState } from "react";
import Header from "../components/Shared/Header";
import Footer from "../components/Shared/Footer";
import ServiceAgain from "../components/Shared/ServiceAgain";
import BannerArea from "../components/Home/BannerArea";
import Testimonial from "../components/Home/Testimonial";
import Fleet from "../components/Home/Fleet";
import CallToAction from "../components/Home/CallToAction";
import Services from "../components/Home/Services";
import { Helmet } from "react-helmet";

export const Home = ({ seoData }) => {
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    const names = [
      "home_main_title",
      "home_sub_title",
      "home_24_7",
      "home_book_a_ride",
      "home_ride_title_airport",
      "home_ride_description_airport",
      "home_ride_title_city",
      "home_ride_description_city",
      "home_ride_title_hourly",
      "home_ride_description_hourly",
      "home_banner_edit_text",
      "home_banner_delete_text",
      "home_slider_one_title",
      "home_slider_one_image",
      "home_slider_one_description",
      "home_slider_two_title",
      "home_slider_two_description",
      "home_slider_two_image",
      "home_slider_three_title",
      "home_slider_three_description",
      "home_slider_three_image",
      "home_slider_four_title",
      "home_slider_four_description",
      "home_slider_four_image",
      "home_fleet_slider_image",
      "home_fleet_main_title",
      "home_fleet_sub_title",
      "home_private_title",
      "home_private_description",
      "home_private_bg_image",
      "home_hero_bg_image",
      "home_service_title",
      "home_service_subtitle",
    ];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/1`)
      .then((res) => res.json())
      .then((resData) => {
        const filterData = resData?.fields?.filter(el=>el.action == 1)
        setMetaData(resData);
        const extractedData = extractValues(filterData, names);
        setData(extractedData);
      });
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="meta_robot" content={metaData?.meta_robot} />
        <meta name="description" content={metaData?.meta_description} />
        <script type="text/json">{metaData?.global_structure_markup}</script>
        <link rel="page_url" href={metaData?.page_url} />
        <title>{metaData?.name}</title>
        <script type="text/json">{seoData && seoData?.google_analytics}</script>
        <script type="text/json">
          {seoData && seoData?.global_structure_markup}
        </script>
        <meta
          name="google_site_verification"
          content={seoData?.site_verification_meta_tag}
        />
      </Helmet>
      <Header />
      <BannerArea
        bannerBg={data?.home_hero_bg_image}
        mainTitle={data?.home_main_title}
        subTitle={data?.home_sub_title}
        cardMainTitle={data?.home_book_a_ride}
        cardTitle1={data?.home_ride_title_airport}
        cardContent1={data?.home_ride_description_airport}
        cardTitle2={data?.home_ride_title_city}
        cardContent2={data?.home_ride_description_city}
        cardTitle3={data?.home_ride_title_hourly}
        cardContent3={data?.home_ride_description_hourly}
        cardEditButton={data?.home_banner_edit_text}
        cardDeleteButton={data?.home_banner_delete_text}
      />
      <Testimonial
        sliderOneTitle={data?.home_slider_one_title}
        sliderOneDescription={data?.home_slider_one_description}
        sliderOneImage={data && data?.home_slider_one_image && JSON.parse(data?.home_slider_one_image)}
        sliderTwoTitle={data?.home_slider_two_title}
        sliderTwoDescription={data?.home_slider_two_description}
        sliderTwoImage={data && data?.home_slider_two_image && JSON.parse(data?.home_slider_two_image)}
        sliderThreeTitle={data?.home_slider_three_title}
        sliderThreeDescription={data?.home_slider_three_description}
        sliderThreeImage={data && data?.home_slider_three_image && JSON.parse(data?.home_slider_three_image)}
        sliderFourTitle={data?.home_slider_four_title}
        sliderFourDescription={data?.home_slider_four_description}
        sliderFourImage={data && data?.home_slider_four_image && JSON.parse(data?.home_slider_four_image)}
      />
      <Fleet
        bgImage={data?.home_fleet_slider_image}
        mainTitle={data?.home_fleet_main_title}
        subTitle={data?.home_fleet_sub_title}
      />
      <CallToAction
        bgImage={data?.home_private_bg_image}
        mainTitle={data?.home_private_title}
        subTitle={data?.home_private_description}
      />
      <Services
        mainTitle={data?.home_service_title}
        subTitle={data?.home_service_subtitle}
      />
      <ServiceAgain />
      <Footer />
    </div>
  );
};
