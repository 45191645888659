import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import GMC from '../../assets/img/gmc.png'
import Cadillac from '../../assets/img/Cadillac.png'
import ChevySurburan from '../../assets/img/Chevy Surburban.png'
import RollsRoyce from '../../assets/img/Rolls Royce.png'

const Fleet = ({home_fleet_slider_image, mainTitle, subTitle}) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows:false
  };


  return (
    <section id="fleet">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>{mainTitle}</h2>
          <p>{subTitle}</p>
          <Slider {...settings} id="owl-carousel-fleet" className="row">
            <div className="item col-lg-11">
                <div className=""><img src={GMC} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={Cadillac} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={ChevySurburan} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={RollsRoyce} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={GMC} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={Cadillac} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={ChevySurburan} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={RollsRoyce} alt="" /></div>
              </div>
        </Slider>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Fleet