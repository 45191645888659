import React from 'react'

const PagesHeader = ({heading, coverImage, bannerImageAltText}) => {
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + coverImage})`,
    alt: bannerImageAltText,
  };

  return (
    <section id="page-titles">
    <div className="service-page-overlay" style={dynamicBackgroundStyle}>

    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>{heading}</h2>
        </div>
      </div>
    </div>
  </section>
  )
}

export default PagesHeader