import React from "react";
import { Link } from "react-router-dom";

const ContactForm = ({
  mainTitle,
  subTitle,
  number,
  landline,
  email,
  serviceTiming,
  salesTiming,
  socialMediaTitle,
  contactFormTitle,
  facebook,
  twitter,
  linkedin,
  youtube,
  instagram,
  pinterest,
}) => {
  return (
    <section id="contact-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact-relative">
              <div className="contact-wh-bg">
                <div className="row">
                  <div className="col-lg-9 offset-lg-3">
                    <div
                      className="mb-3 pb-5 pt-4"
                      style={{ paddingLeft: "70px", paddingRight: "37px" }}
                      id="margin-left-per"
                    >
                      <h2 className="pb-3">{contactFormTitle}</h2>
                      <form
                        action="get"
                        id="contact-form"
                        className="forms mt-3"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input_type mb-3">
                              <div className="input-group align-items-center bg-white rounded">
                                <span className="input-group-addon csaddn">
                                  <i className="fa fa-user"></i>
                                </span>
                                <input
                                  id="name"
                                  className="form-control py-2"
                                  type="text"
                                  placeholder="Name"
                                  style={{ boxShadow: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input_type mb-3">
                              <div className="input-group align-items-center bg-white rounded">
                                <span className="input-group-addon csaddn">
                                  <i className="fa fa-phone"></i>
                                </span>
                                <input
                                  id="phone"
                                  className="form-control py-2"
                                  type="number"
                                  placeholder="Phone"
                                  style={{ boxShadow: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input_type mb-3">
                              <div className="input-group align-items-center bg-white rounded">
                                <span className="input-group-addon csaddn">
                                  <i className="fa fa-at"></i>
                                </span>
                                <input
                                  id="email"
                                  className="form-control py-2"
                                  type="email"
                                  placeholder="Email"
                                  style={{ boxShadow: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="input_type mb-3">
                              <div className="input-group align-items-center bg-white rounded">
                                <span className="input-group-addon csaddn">
                                  <i className="fa fa-clock"></i>
                                </span>
                                <input
                                  id="time"
                                  className="form-control py-2"
                                  type="time"
                                  placeholder="00 00 AM"
                                  style={{ boxShadow: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="input_type mb-3">
                              <div className="input-group align-items-center bg-white rounded">
                                <span className="input-group-addon csaddn">
                                  <i className="fa-solid fa-calendar"></i>
                                </span>
                                <input
                                  id="date"
                                  className="form-control py-2"
                                  type="date"
                                  placeholder="mm  dd  yyyy"
                                  style={{ boxShadow: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input_type mb-3">
                              <div className="input-group align-items-center bg-white rounded">
                                <span className="input-group-addon csaddn">
                                  <i className="fa-solid fa-file-lines"></i>
                                </span>
                                <input
                                  id="subject"
                                  className="form-control py-2"
                                  type="text"
                                  placeholder="Subject"
                                  style={{ boxShadow: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="input_type mb-3">
                              <div className="input-group ">
                                <span className="input-group-addon csaddn">
                                  <i className="fa-solid fa-envelope mt-3"></i>
                                </span>
                                <textarea
                                  name="Message"
                                  id="Message"
                                  placeholder="Message"
                                  className="form-control"
                                  style={{ boxShadow: "none", paddingTop: "13px" }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="submit_button">
                              <Link
                                to={"/"}
                                className="btn btn-contact-submit"
                                role="button"
                              >
                                Submit Form
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <div className="contact-ylw-bg py-0 px-4">
                    <h2>{mainTitle}</h2>
                    <h6>{subTitle}</h6>
                    <ul className="contact-details">
                      <li>
                        <i className="fa-solid fa-phone"></i>
                        {number}
                      </li>
                      <li>
                        <i className="fa-solid fa-mobile"></i>
                        {landline}
                      </li>
                      <li>
                        <i className="fa-solid fa-at"></i>
                        {email}
                      </li>
                      <li>
                        <i className="fa-solid fa-clock"></i>
                        {serviceTiming}
                      </li>
                      <li>
                        <i className="fa-solid fa-clock"></i>
                        {salesTiming}
                      </li>
                    </ul>
                    <h3>{socialMediaTitle}</h3>
                    <ul className="social-contact">
                      <li>
                        <Link to={facebook}>
                          <i className="fa-brands fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={twitter}>
                          <i className="fa-brands fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={linkedin}>
                          <i className="fa-brands fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={youtube}>
                          <i className="fa-brands fa-youtube"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={instagram}>
                          <i className="fa-brands fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={pinterest}>
                          <i className="fa-brands fa-pinterest"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
