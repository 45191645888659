import React from 'react'
import ProductDetailImage from '../../assets/img/gmc-description.png';

const ProductDetailHeader = ({carName, coverImage, backgroundImage}) => {
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + backgroundImage?.[0].name})`
  };
  return (
    <section id="page-titles" style={{ paddingBottom:"150px"}}>
    <div className="rates-overlay" style={dynamicBackgroundStyle}>

    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h2 className='pb-0'>{carName}</h2>
        </div>
        <div className="col-lg-4">
            <div className="title-content" style={{ position:"absolute", bottom:"-35px"}}>
                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL + coverImage}`} alt="car" width="460" height="270" />
            </div>
        </div>
      </div>
    </div>
  </section>

  )
}

export default ProductDetailHeader