import React, { useState, useEffect } from "react";
import GMC from "../../assets/img/gmc.png";
import RightCircleArrow from "../../assets/img/Arrow - Right Circle.svg";
import Cadillac from "../../assets/img/Cadillac.png";
import ChevySurburban from "../../assets/img/Chevy Surburban.png";
import RollsRoyce from "../../assets/img/Rolls Royce.png";
import { Link, useNavigate } from "react-router-dom";

const RatesContent = ({ mainTitle }) => {
  const [data, setData] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const navigate = useNavigate([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/vehicle/get`)
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
        const newCoverImages = resData?.map(el=>el?.imgurls?.filter((el) => el.name.includes('coverImage')));
        setCoverImages(newCoverImages)
      });
  }, []);

  const handleBookNow = (id) => {
    navigate(`/product-detail/${id}`);
  }
  return (
    <section id="rate-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="nav-control">
              {/* <p>{mainTitle?.replace(/\s+/g, " ").trim()}</p> */}
              <p>ALL / SEDAN / SUV / SPECIALTY VEHICLES</p>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((el, i) => {
          return (
            <div key={i} className="col-lg-4">
              <div className="sigle-rate">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL + coverImages[i][0].name}`}
                  // src={`http://localhost:8080/${coverImage?.[0].name}`}
                  alt="car"
                />
                <h5>{el.name}</h5>
                <h6>
                  <span>${el.rate}</span> /HOUR
                </h6>
                <ul id="rate-details">
                  <li>
                    <i className="fa-solid fa-car"></i>
                    {el.max_passenger} Passengers - {el.max_bags} Luggages
                  </li>
                  <li>
                    <i className="fa-solid fa-car"></i>Captain leather chairs
                    second-row
                  </li>
                  <li>
                    <i className="fa-solid fa-car"></i>Power fold second and
                    third-row seats
                  </li>
                  <li>
                    <i className="fa-solid fa-car"></i>Spacious luggage
                    compartment
                  </li>
                </ul>
                <a
                  onClick={()=>handleBookNow(el.id)}
                  className="btn btn-main hvr-icon-forward"
                  role="button"
                >
                  Book now{" "}
                  <img className="hvr-icon" src={RightCircleArrow} alt="icon" />
                </a>
              </div>
            </div>
          )})}
          {/* <div className="col-lg-4">
            <div className="sigle-rate">
              <img src={GMC} alt="" />
              <h5>GMC Yukon Danali Surburban</h5>
              <h6>
                <span>$140</span> /HOUR
              </h6>
              <ul id="rate-details">
                <li>
                  <i className="fa-solid fa-car"></i>6 Passengers - 6 Luggages
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Captain leather chairs
                  second-row
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Power fold second and
                  third-row seats
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Spacious luggage
                  compartment
                </li>
              </ul>
              <a
                href="gmc-description.html"
                className="btn btn-main hvr-icon-forward"
                role="button"
              >
                Book now{" "}
                <img className="hvr-icon" src={RightCircleArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sigle-rate">
              <img src={Cadillac} alt="" />
              <h5>Cadillac</h5>
              <h6>
                <span>$140</span> / HOUR
              </h6>
              <ul id="rate-details">
                <li>
                  <i className="fa-solid fa-car"></i>6 Passengers - 6 Luggages
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Captain leather chairs
                  second-row
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Power fold second and
                  third-row seats
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Spacious luggage
                  compartment
                </li>
              </ul>
              <Link
                to={"/"}
                className="btn btn-main hvr-icon-forward"
                role="button"
              >
                Book now{" "}
                <img className="hvr-icon" src={RightCircleArrow} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sigle-rate">
              <img src={ChevySurburban} alt="" />
              <h5>Chevy Surburban</h5>
              <h6>
                <span>$140</span> / HOUR
              </h6>
              <ul id="rate-details">
                <li>
                  <i className="fa-solid fa-car"></i>6 Passengers - 6 Luggages
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Captain leather chairs
                  second-row
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Spacious luggage
                  compartment
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Tinted privacy glass
                </li>
              </ul>
              <Link
                to={"/"}
                className="btn btn-main  hvr-icon-forward"
                role="button"
              >
                Book now{" "}
                <img className="hvr-icon" src={RightCircleArrow} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sigle-rate">
              <img src={RollsRoyce} alt="" />
              <h5>Rolls Royce </h5>
              <h6>
                <span>$140</span> / HOUR
              </h6>
              <ul id="rate-details">
                <li>
                  <i className="fa-solid fa-car"></i>3 Passengers - 3 Luggages
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Black Leather Interior
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Large leg space
                </li>
                <li>
                  <i className="fa-solid fa-car"></i>Rear-seat Console Controls
                </li>
              </ul>
              <Link
                to={"/"}
                className="btn btn-main  hvr-icon-forward"
                role="button"
              >
                Book now{" "}
                <img className="hvr-icon" src={RightCircleArrow} alt="" />
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default RatesContent;
