import React, { useEffect, useState } from "react";
import Header from "../components/Shared/Header";
import Footer from "../components/Shared/Footer";
import ServiceAgain from "../components/Shared/ServiceAgain";
import ProductDetailHeader from "../components/ProductDetail/ProductDetailHeader";
import ProductDetailContent from "../components/ProductDetail/ProductDetailContent";
import { useParams } from "react-router-dom";

export const ProductDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [carDetails, setCarDetails] = useState();
  const [carName, setCarName] = useState();
  const [coverImage, setCoverImage] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/vehicle/getById/${id}`)
      .then((res) => res.json())
      .then((resData) => {
        setCarDetails(resData);
        setCoverImage(
          resData?.imgurls?.filter((el) => el?.name?.includes("coverImage"))[0]
            .name
        );
        setCarName(resData.name);
      });
  }, []);

  useEffect(() => {
    const names = ["rates_bg_image"];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/3`)
      .then((res) => res.json())
      .then((resData) => {
        const filterData = resData?.fields?.filter(el=>el.action == 1)
        const extractedData = extractValues(filterData, names);
        setData(extractedData);
      });
  }, []);

  return (
    <div>
      <Header />
      <ProductDetailHeader
        carName={carName}
        coverImage={coverImage}
        backgroundImage={data && JSON.parse(data?.rates_bg_image)}
      />
      <ProductDetailContent carDetails={carDetails} coverImage={coverImage} />
      <ServiceAgain />
      <Footer />
    </div>
  );
};
