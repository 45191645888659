import React, { useEffect, useState } from "react";
import Header from "../components/Shared/Header";
import Footer from "../components/Shared/Footer";
import ServiceAgain from "../components/Shared/ServiceAgain";
import PagesHeader from "../components/Pages/PagesHeader";
import PagesContent from "../components/Pages/PagesContent";
import { Helmet } from "react-helmet";

export const PageService = ({
  title,
  heading,
  shortDescription,
  content,
  images,
  bannerImageAltText,
  iconAltText,
  pageImageAltText,
  metaRobot,
  pageUrl,
  metaDescription,
  globalStructureMarkup,
  seoData,
}) => {
  const [coverImage, setCoverImage] = useState();
  const [contentImage, setContentImage] = useState();
  const [pageIcon, setPageIcon] = useState();
  useEffect(() => {
    images?.map((el) => {
      if (el.name.includes("banner")) {
        setCoverImage(el.name);
      }
      if (el.name.includes("page")) {
        setContentImage(el.name);
      }
      if (el.name.includes("icon")) {
        setPageIcon(el.name);
      }
    });
  }, [images]);

  return (
    <div>
      <Helmet>
        <meta name="meta_robot" content={metaRobot} />
        <meta name="description" content={metaDescription} />
        <script type="text/json">{globalStructureMarkup}</script>
        <link rel="page_url" href={pageUrl} />
        <title>{title}</title>
         <script type="text/json">{seoData && seoData?.google_analytics}</script>
         <script type="text/json">{seoData && seoData?.global_structure_markup}</script>
        <meta
          name="google_site_verification"
          content={seoData?.site_verification_meta_tag}
        />
      </Helmet>
      <Header />
      <PagesHeader
        heading={title}
        coverImage={coverImage}
        bannerImageAltText={bannerImageAltText}
      />
      <PagesContent
        heading={heading}
        metaDescription={shortDescription}
        content={content}
        contentImage={contentImage}
        pageIcon={pageIcon}
        iconAltText={iconAltText}
        pageImageAltText={pageImageAltText}
      />
      <ServiceAgain />
      <Footer />
    </div>
  );
};
