import React, { useEffect, useState } from "react";
import Header from "../components/Shared/Header";
import Footer from "../components/Shared/Footer";
import ServiceAgain from "../components/Shared/ServiceAgain";
import BlogHeader from "../components/Blog/BlogHeader";
import Posts from "../components/Blog/Posts";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

export const Blog = ({ seoData }) => {
  const [metaData, setMetaData] = useState();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/page/4`)
      .then((res) => res.json())
      .then((resData) => {
        setMetaData(resData);
      });
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="meta_robot" content={metaData?.meta_robot} />
        <meta name="description" content={metaData?.meta_description} />
        <script type="text/json">{metaData?.global_structure_markup}</script>
        <link rel="page_url" href={metaData?.page_url} />
        <title>{metaData?.name}</title>
        <script type="text/json">{seoData && seoData?.google_analytics}</script>
        <script type="text/json">
          {seoData && seoData?.global_structure_markup}
        </script>
        <meta
          name="google_site_verification"
          content={seoData.site_verification_meta_tag}
        />
      </Helmet>
      <Header />
      <BlogHeader />
      <Posts />
      <ServiceAgain />
      <Footer />
    </div>
  );
};
