import React, { useEffect, useState } from 'react'
import Header from '../components/Shared/Header'
import Footer from '../components/Shared/Footer'
import ServiceAgain from '../components/Shared/ServiceAgain'
import { useParams } from 'react-router-dom'
import BlogDetailHeader from '../components/BlogDetail/BlogDetailHeader'
import BlogDetailContent from '../components/BlogDetail/BlogDetailContent'

export const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState()
  const [comments, setComments] = useState()
  const [replies, setReplies] = useState()
  const [coverImage, setCoverImage] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/blogs/${id}`)
      .then((res) => res.json())
      .then((resData) => {
        setBlog(resData);
        setCoverImage(resData && resData?.urls && JSON.parse(resData?.urls).filter(el=>el?.name?.includes("Image"))[0]?.name)
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/blogs/${id}/comments/commentIdZero`)
      .then((res) => res.json())
      .then((resData) => {
        setComments(resData);
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/blogs/${id}/comments/commentIdNotZero`)
      .then((res) => res.json())
      .then((resData) => {
        setReplies(resData);
      });
  }, []);

  return (
    <div>
        <Header/>
        <BlogDetailHeader coverImage={coverImage} />
        <BlogDetailContent blog={blog} comments={comments} replies={replies} coverImage={coverImage} />
        <ServiceAgain/>
        <Footer/>
    </div>
  )
}
